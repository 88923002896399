import styled from "@emotion/styled";
import { Box, Grid, LinearProgress, ListItem, Typography } from "@mui/material"
import axios from "axios"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#fff",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#F0F0F0",
    },
}));

const ConsolidatedOrdersIndex = props => {
    const { enqueueSnackbar } = useSnackbar()
    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        setLoadingProducts(true)
        async function getData(){
            await axios.get('https://retailbs.com.au/seasonal/get_orders_consolidated.php', {signal: controller.signal}).then(res=>{
                if(res.data){
                    setProducts(res.data)
                    console.log(res.data)
                }}).catch(err=>{
                    enqueueSnackbar("An error occured loading consolidated order details, please try again.", {variant: 'error'})
                })
                setLoadingProducts(false)
            }
        getData()
        return () => {
            controller.abort();
        }
    }, [enqueueSnackbar])
    
    if(loadingProducts){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">Loading Consolidated Order Details...</Typography>
        <LinearProgress color="warning" />
      </Box>
    }

    if(products.length === 0){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">Nothing to display here!</Typography>
      </Box>
    }

    let ordersList = products.map((value,key)=>{
        return <StyledListItem key={key} sx={{borderBottom: '1px solid #000', borderTop: '1px solid #000'}}><Grid item xs={2}>
        <Typography variant="body1" sx={{textAlign: 'center', fontSize:'1.5rem'}}><b>{value.total}</b></Typography>
    </Grid>
    <Grid item xs={10}>
        <Typography variant="body1" sx={{textTransform: 'uppercase', fontSize:'1.5rem'}}><b>{value.name}</b></Typography>
    </Grid></StyledListItem>
    })

    return (
        <Box sx={{mt:3}}>
            <Grid container spacing={1}>
                <Grid item xs={2} sx={{textAlign: 'center'}}>
                    <Typography variant="overline"><b>ORDER QTY</b></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="overline"><b>PRODUCT</b></Typography>
                </Grid>
                {ordersList}
            </Grid>
        </Box>
    )
}

export default ConsolidatedOrdersIndex