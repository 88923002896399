import styled from "@emotion/styled"
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material"
import axios from "axios"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ProductCard from "./ProductCard"

function english_ordinal_suffix(dt){
    return dt.getDate()+(dt.getDate() % 10 === 1 && dt.getDate() !== 11 ? 'st' : (dt.getDate() % 10 === 2 && dt.getDate() !== 12 ? 'nd' : (dt.getDate() % 10 === 3 && dt.getDate() !== 13 ? 'rd' : 'th'))); 
}

const StyledButton = styled(Button)(({ theme }) => ({
    "margin": ".5rem"
}));

const ViewOrder = props => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [order, setOrder] = useState([])
    const [loadingOrder, setLoadingOrder] = useState(true)


    useEffect(() => {
        const controller = new AbortController();
        setLoadingOrder(true)
        async function getData(){
            await axios.get('https://retailbs.com.au/seasonal/get_order_details.php?id='+parseInt(id), {signal: controller.signal}).then(res=>{
                if(res.data){
                    setOrder(res.data)
                }
                }).catch(err=>{
                    enqueueSnackbar("An error occured loading orders, please try again.", {variant: 'error'})
                })
                setLoadingOrder(false)
            }
        getData()
        return () => {
            controller.abort();
        }
    }, [enqueueSnackbar, id])
    
    if(loadingOrder){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">Loading Order Details...</Typography>
        <LinearProgress color="warning" />
      </Box>
    }

    if(order.length === 0){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">Unable to display order details.</Typography>
      </Box>
    }

    let products = order.products.map((val,idx)=> <ProductCard key={idx} product={val} />)
    let day = new Date(order.header.collection)
    let dayFormatted = day.toLocaleDateString("en-us", {weekday: "long"}) + " " + english_ordinal_suffix(day) + " " + day.toLocaleDateString("en-us", {month: "long"}) + ", " + day.getFullYear()

    return (
        <>
        <Box sx={{ display: 'block', displayPrint: 'none' }}>
            <StyledButton onClick={()=>navigate(-1)} size="small" variant="contained" color="error">Return to Orders</StyledButton>
            <StyledButton onClick={()=>window.print()} size="small" variant="contained" color="warning">Print Order</StyledButton>
        </Box>
        <Typography variant='h5' component="div" sx={{textTransform: 'uppercase'}}>{order.header.name}</Typography>
        <Typography variant="overline" component="div" sx={{textTransform: 'uppercase'}}>{order.header.phone} ({order.header.email})</Typography>
        <Typography variant='overline' component="div" sx={{textTransform: 'uppercase'}}>Order placed - {new Date(order.header.created).toLocaleString('en-au')}</Typography>  
        
        <Typography variant='subtitle1' component="div" sx={{textTransform: 'uppercase', fontWeight:'bold', border: '5px solid red', textAlign:'center'}}>Collection Date - {dayFormatted}</Typography>
        {(order.header.notes) && <Typography variant='subtitle1' component="div" sx={{textTransform: 'uppercase', fontWeight:'bold', border: '5px solid gold', textAlign:'center', marginTop:'15px'}}><u><small>ADDITIONAL NOTES</small></u><br />{order.header.notes}</Typography>}
        <Box sx={{mt:3}}>
            <Grid container spacing={1}>
                <Grid item xs={2} sx={{textAlign: 'center'}}>
                    <Typography variant="overline"><b>ORDER QTY</b></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="overline"><b>PRODUCT</b></Typography>
                </Grid>
                {products}
            </Grid>
        </Box>
        </>
    )
}

export default ViewOrder