import styled from "@emotion/styled";
import { Grid, ListItem, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from "react";
import { SidebarContext } from "../Context/SidebarContext";

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "#fff",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#F0F0F0",
    },
}));

const ProductTile = props => {
    const { setIsOpen, setId, setCategoryList } = useContext(SidebarContext);
    const { product, categories } = props
    let weight = ""
    if(product.is_weigh === "1"){
        weight = "/kg"
    }

    return (<StyledListItem sx={{borderBottom: '1px solid #000', borderTop: '1px solid #000'}}>
        <Grid item xs={1} sx={{textAlign:'center'}}>
            <EditIcon onClick={()=>{setId(product); setCategoryList(categories); setIsOpen(true)}} sx={{cursor: 'pointer'}} fontSize="large" color="error" />
        </Grid>
        <Grid item xs={11}>
            <Typography variant="body1" sx={{textTransform: 'uppercase'}}><b>{product.name}</b> {(product.is_weigh === "1") ? <b><small>(KG)</small></b> : <b><small>(EA)</small></b>}</Typography>
            <Typography variant="caption" sx={{textTransform: 'uppercase'}}>{product.caption}</Typography>
            <Typography variant="caption" component="div" sx={{textTransform: 'uppercase'}}>{(product.price !== '0.00') ? "Est Price: $"+product.price+weight : "Est Price: N/A"}</Typography>
            {(product.weight !== "0.00") &&
            <Typography variant="caption" component="div" sx={{textTransform: 'uppercase'}}>Approx Weight: {product.weight}kg</Typography>
    }
        </Grid>
    </StyledListItem>)
}

export default ProductTile