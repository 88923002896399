import { Container } from "@mui/system";
import { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import SideDrawer from './Cart/SideDrawer';
import { SidebarContext } from "./Context/SidebarContext";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from 'notistack';
import Header from "./Header";
import ProductIndex from "./Products/Index";
import { Box, Typography } from "@mui/material";
import OrdersIndex from "./Orders/Index";
import ConsolidatedOrdersIndex from "./ConsolidatedOrders/Index";
import ViewOrder from "./Orders/ViewOrder";

const HeaderElem = <Box sx={{ display: 'block', displayPrint: 'none' }}>
  <Header  />
</Box>

const router = createBrowserRouter([
  {
    path: "/",
    element: <>
    {HeaderElem}
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <ProductIndex />
    </Container>
    <SideDrawer />
  </>
  },
  {
    path: "/orders",
    element: <>
    {HeaderElem}
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <OrdersIndex />
    </Container>
  </>
  },
  {
    path: "/consolidatedorders",
    element: <>
    {HeaderElem}
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <ConsolidatedOrdersIndex />
    </Container>
  </>
  },
  {
    path: "/order/:id",
    element: <>
    {HeaderElem}
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <ViewOrder />
    </Container>
  </>
  },
  {
    path: "*",
    element: <>
    {HeaderElem}
    <Container maxWidth="xl" sx={{marginTop: '20px'}}>
      <Typography variant="h4" color="error" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>Ooops!</Typography>
      <Typography variant="h4" color="primary" sx={{fontWeight: 'bold', textTransform: 'uppercase', mt:3}}>Page Not Found</Typography>
    </Container>
  </>
  }
]);

const App = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [id, setId] = useState(null)
  const [categories, setCategoryList] = useState([])


  return (
    
      <SidebarContext.Provider value={{isOpen, setIsOpen, id, setId, categories, setCategoryList}}>
          <SnackbarProvider maxSnack={3}>
            <ConfirmProvider>
              <RouterProvider router={router} />
            </ConfirmProvider>
          </SnackbarProvider>
      </SidebarContext.Provider>
  );
}

export default App;
